import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Button from "../components/button";
import Seo from "../components/seo";
import CalloutSection from "../sections/callout-section";
import HowToDonateSection from "../sections/how-to-donate-section";
import AboutSection from "../sections/text-section";
import Layout from "./layouts/layout";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Layout mdxType="Layout">
      <Seo title="Join Us" mdxType="Seo" />
      <AboutSection classes={"card"} maxwidth={"700px"} mdxType="AboutSection">
        <h1>{`Sign up your company to receive your official collection kit!`}</h1>
        <p>{`If your business would like to collect toy donations for the KGW Great Toy Drive, please click below and fill out the form.`}</p>
        <p><strong parentName="p">{`Collect supplies now through December 14`}</strong></p>
        <Button href={"https://docs.google.com/forms/d/e/1FAIpQLSchN9ybOYPFauJBsAt1zlVRUpOwTO-UKy_1TtIttwF6tj_T0Q/viewform?usp=sf_link"} target={"_blank"} mdxType="Button">
  Click here to sign up
        </Button>
        <p>{`Thank you for caring about our community!`}</p>
      </AboutSection>
      <HowToDonateSection mdxType="HowToDonateSection" />
    </Layout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      